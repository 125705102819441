.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.swal-button2 {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #21BA45;
  font-size: 12px;
  border: 1px solid #08f26e;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}


/*
We set the element we are applying our loading mask to relative
*/
.loading-mask {
  position: relative;
}

/*
Because we set .loading-mask relative, we can span our ::before
element over the whole parent element
*/
.loading-mask::before {
  content: '';
  z-index: 10000;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
}

/*
Spin animation for .loading-mask::after
*/
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

/*
The loading throbber is a single spinning element with three
visible borders and a border-radius of 50%.
Instead of a border we could also use a font-icon or any
image using the content attribute.
*/
.loading-mask::after {
  content: "";
  z-index: 10000;
  position: absolute;
  border-width: 3px;
  border-style: solid;
  border-color: transparent rgb(255, 255, 255) rgb(255, 255, 255);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  animation: 2s linear 0s normal none infinite running spin;
  filter: drop-shadow(0 0 2 rgba(0, 0, 0, 0.33));
}
